import {
    Box,
    Header,
    SpaceBetween,
    Table,
    Button,
    Modal,
    Icon,
    RadioGroup,
    FormField,
    DatePicker,
    Flashbar,
    Link,

} from "@cloudscape-design/components";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { CompanyInfoSuperAdmin } from "./CompanyInfo";
import { useNavigate } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import {
    listCompanies as listCompaniesQuery,
    listCompanyOwners as listCompanyOwnersQuery,
    usersByCompanyId,
} from "../graphql/queries";
import { Company, CompanyOwner } from "../API";
import { iCompanyOwner } from "../types";
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { useCompanyContext } from "../contexts/CompanyContext";
import { SUBCRIPTION_TYPE_AND_DESCRIPTION } from "../utils/constants";
import { deleteCompany, deleteCompanyOwner, updateCompany, updateCompanyOwner } from "../graphql/mutations";
import { formatDate, isDateAfter, isDateInFuture, willExpireWithinAWeek } from "../utils/helperFunctions";
const client = generateClient({ authMode: "apiKey" });


export const SuperAdminDashboardContent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isFreeDemo, setIsFreeDemo] = useState<boolean>(false)
    const [isLimitedSubscription, setIsLimitedSubscription] = useState<boolean>(false)
    const [isPausedSubscription, setIsPausedSubscription] = useState<boolean>(false)
    const [numberOfCompanies, setNumberOfCompanies] = useState(0)
    const [displayData, setDisplayData] = useState<iCompanyOwner[]>([]);
    const [companyOwnerData, setCompanyOwnerData] = useState<CompanyOwner[]>([]);
    const [currentCompanyOwner, setCurrentCompanyOwner] = useState<CompanyOwner>();
    const [companyDataByOwner, setCompanyDataByOwner] = useState<Company[]>([]);
    const [currentCompanyId, setCurrentCompanyId] = useState<string | null>(null)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
    const [deleteModalInfo, setDeleteModalInfo] = useState<iCompanyOwner | null>(null)
    const [isEditExpirationModalOpen, setIsEditExpirationModalOpen] = useState<boolean>(false)
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false)
    const [isExpired, setIsExpired] = useState<boolean>(false)
    const [editExpirationModalInfo, setEditExpirationModalInfo] = useState<iCompanyOwner | null>(null)
    const [isEditSubscriptionTypeModalOpen, setIsEditSubscriptionTypeModalOpen] = useState<boolean>(false)
    const [editSubscriptionTypeInfo, setEditSubscriptionTypeInfo] = useState<iCompanyOwner | null>(null)
    const [subscriptionType, setSubscriptionType] = useState<string | null>("")
    const [expirationDate, setExpirationDate] = useState<string | null>("")
    const [siteAdmin, setSiteAdmin] = useState<boolean>(false)
    const { setCompany } = useCompanyContext();
    const { user, route } = useAuthenticator((context) => [
        context.user,
        context.route,
    ]);
    const navigate = useNavigate()

    const getCompanyOwnerData = async () => {
        setIsLoading(true);
        try {
            const companyOwnerResponse = await client.graphql({
                query: listCompanyOwnersQuery,
            });
            const allCompanyOwnersData =
                companyOwnerResponse?.data?.listCompanyOwners?.items

            const currentCompanyOwner = allCompanyOwnersData.find((owner) => owner.id === user.userId)

            setCurrentCompanyOwner(currentCompanyOwner as CompanyOwner)

            if (currentCompanyOwner) {
                setSiteAdmin(currentCompanyOwner.siteAdmin)
                setIsFreeDemo(currentCompanyOwner.subscriptionType === "free-demo")
                setIsLimitedSubscription(currentCompanyOwner.subscriptionType === "paid-limited-access")
                setIsPausedSubscription(currentCompanyOwner.subscriptionType === "paused")

            }

            setCompanyOwnerData(allCompanyOwnersData as CompanyOwner[]);

            const companyResponse = await client.graphql({
                query: listCompaniesQuery,
            });
            const allCompanyData =
                companyResponse?.data?.listCompanies.items
            const companiesCurrentOwner: Company[] = allCompanyData.filter((owner) => owner.companyOwnerId === user.userId) as Company[]

            setNumberOfCompanies(companiesCurrentOwner.length)
            if (companiesCurrentOwner.length === 1) {
                setCompany(companiesCurrentOwner[0])
                const expirationDate = companiesCurrentOwner[0].subscriptionExpiration as string

                const today = new Date();
                const formattedToday = formatDate(today);

                setIsExpired(isDateAfter(expirationDate, formattedToday));
            } else if (companiesCurrentOwner.length > 1) {
                companiesCurrentOwner.forEach((company) => {
                    const expirationDate = company.subscriptionExpiration as string

                    const today = new Date();
                    const formattedToday = formatDate(today);

                    if (isDateAfter(expirationDate, formattedToday)) {
                        setIsExpired(true)
                    }
                })
            }

            setCompanyDataByOwner(allCompanyData as Company[]);

        } catch (error) {
            console.error("error :>> ", error);
            Sentry.captureException(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubscriptionChange = async (value: string) => {
        try {
            // updates the subscriptionType on the CompanyOwner
            if (editSubscriptionTypeInfo) {
                const companyOwnerInput = {
                    id: editSubscriptionTypeInfo.id as string,
                    subscriptionType: value as string
                };
                const result = await client.graphql({ query: updateCompanyOwner, variables: { input: companyOwnerInput } })
                setCompanyOwnerData(prevData =>
                    prevData.map(owner =>
                        owner.id === companyOwnerInput.id
                            ? { ...owner, ...result.data.updateCompanyOwner }
                            : owner
                    )
                );

                // updates the subscriptionType for the Companies associated with the companyOwner
                const currentCompanies = companyDataByOwner.filter((company) => company.companyOwnerId === editSubscriptionTypeInfo.id)
                const updatePromises = currentCompanies.map(company => {
                    const companyInput = {
                        id: company.id, // Use the ID from each company
                        subscriptionType: value, // New subscription type
                    };

                    return client.graphql({
                        query: updateCompany,
                        variables: { input: companyInput },
                    });
                })
                const results = await Promise.all(updatePromises);

                setCompanyDataByOwner(prevData =>
                    prevData.map(company => {
                        const updatedCompany = results.find(
                            result => result.data.updateCompany.id === company.id
                        );
                        return updatedCompany
                            ? { ...company, ...updatedCompany.data.updateCompany }
                            : company;
                    })
                );

            }
        } catch (error) {
            console.error("Error updating subscription:", error);
        } finally {
            setIsEditSubscriptionTypeModalOpen(false)
        }
    }


    const handleExpirationDateChange = async (value: string) => {
        try {
            // updates the subscriptionType on the CompanyOwner
            if (editExpirationModalInfo) {
                // updates the subscriptionType for the Companies associated with the companyOwner
                const currentCompanies = companyDataByOwner.filter((company) => company.companyOwnerId === editExpirationModalInfo.id)

                const formatDate = (dateString: string | number | Date) => {
                    const date = new Date(dateString);
                    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
                    const day = date.getUTCDate().toString().padStart(2, '0');
                    const year = date.getUTCFullYear();
                    return `${month}/${day}/${year}`;

                }

                const formattedDate = formatDate(value as string)

                const currentCompany = currentCompanies.find(company => company.id === currentCompanyId)
                if (currentCompany) {
                    const companyInput = {
                        id: currentCompany.id, // Use the ID from each company
                        subscriptionExpiration: formattedDate, // New expiration date
                    };

                    const result = await client.graphql({
                        query: updateCompany,
                        variables: { input: companyInput },
                    });


                    setCompanyDataByOwner(prevData =>
                        prevData.map(company =>
                            company.id === result.data.updateCompany.id
                                ? { ...company, ...result.data.updateCompany }
                                : company
                        ))
                }
            }

        } catch (error) {
            console.error("Error updating subscription:", error);
        } finally {
            setIsEditExpirationModalOpen(false)
        }
    }

    const handleDeleteCompanyOwner = async () => {
        if (deleteModalInfo) {
            try {
                const result = await client.graphql({
                    query: deleteCompanyOwner,
                    variables: { input: { id: deleteModalInfo.id as string } },
                });

                const currentCompanies = companyDataByOwner.filter((company) => company.companyOwnerId === deleteModalInfo.id)
                const updatePromises = currentCompanies.map(company => {
                    const companyInput = {
                        id: company.id,
                        subscriptionType: "paused",
                    };

                    return client.graphql({
                        query: updateCompany,
                        variables: { input: companyInput },
                    });
                })
                const results = await Promise.all(updatePromises);

                setDeleteModalInfo(null);
                getCompanyOwnerData();
            } catch (error) {
                console.error("error :>> ", error);
                Sentry.captureException(error);
            } finally {
                setIsConfirmed(false)
            }
        }
    };

    const handleSingleCompanyDelete = async (companyId: string) => {
        if (deleteModalInfo) {
            try {
                const result = await client.graphql({
                    query: deleteCompany,
                    variables: { input: { id: companyId } },
                });
                setDeleteModalInfo(null);
                getCompanyOwnerData();

            } catch (error) {
                console.error("error :>> ", error);
                Sentry.captureException(error);
            } finally {
                setIsConfirmed(false)
            }
        }
    };

    useEffect(() => {
        if (user) {
            getCompanyOwnerData();
        }
    }, []);

    useEffect(() => {

        const combinedData: iCompanyOwner[] = companyOwnerData.map(owner => ({
            ...owner,
            companies: companyDataByOwner.filter(company => company.companyOwnerId === owner.id),
        }));

        setDisplayData(combinedData)
    }, [companyDataByOwner, companyOwnerData])

    return (
        <>{!isLoading && (!siteAdmin ? (
            <SpaceBetween direction="vertical" size="m">

                <Header variant="h1" id="page-top">Welcome Administrator!</Header>
                {isFreeDemo ? (<Flashbar items={[{
                    type: "info",
                    dismissible: false,
                    content: (
                        <>
                            Upgrade to Paid Subscription {" "}
                            <Link color="inverted" href="/subscription">
                                Click for Info
                            </Link>
                        </>
                    ),
                    id: "is free demo message"
                }]}
                />) : isLimitedSubscription ? (<Flashbar items={[{
                    type: "info",
                    dismissible: false,
                    content: (
                        <>
                            Manage Subscription - Cancel or Upgrade {" "}
                            <Link color="inverted" href="/subscription">
                                Click for Info
                            </Link>
                        </>
                    ),
                    id: "has limited subscription message"
                }]}
                />) : isPausedSubscription ? (<Flashbar items={[{
                    type: "info",
                    dismissible: false,
                    content: (
                        <>
                            Subscription Paused - Resume Now {" "}
                            <Link color="inverted" href="/subscription">
                                Click for Info
                            </Link>
                        </>
                    ),
                    id: "has paused expiration message"
                }]}
                />) : (<Flashbar items={[{
                    type: "info",
                    dismissible: false,
                    content: (
                        <>
                            Manage Subscription - Cancel or Downgrade {" "}
                            <Link color="inverted" href="/subscription">
                                Click for Info
                            </Link>
                        </>
                    ),
                    id: "has active full subscription message"
                }]}
                />)}


                {numberOfCompanies >= 1 ? <CompanyInfoSuperAdmin /> : <><Button onClick={() => navigate("/admin", { replace: true })}>Please Add Your Company</Button></>}
            </SpaceBetween>
        ) : (
            <>
                <SpaceBetween size="m">
                    <Header variant="h1" id="page-top">Welcome Site Admin!</Header>

                    <Table
                        // variant="embedded"
                        header={
                            <Header
                                variant="h2"
                                counter={`(${companyOwnerData?.length || 0})`}
                            >
                                Company Owners
                            </Header>
                        }
                        wrapLines
                        columnDisplay={[
                            {
                                id: "name",
                                visible: true,
                            },
                            {
                                id: "email",
                                visible: true,
                            },
                            {
                                id: "subscriptionType",
                                visible: true,
                            },
                            {
                                id: "users",
                                visible: false,
                            },
                            {
                                id: "companies",
                                visible: true,
                            },
                            {
                                id: "delete",
                                visible: true,
                            },
                        ]}
                        columnDefinitions={[
                            {
                                id: "name",
                                header: "Name",
                                cell: (item) => item.name || "--",
                            },
                            {
                                id: "email",
                                header: "Email",
                                cell: (item) => item.email,
                            },
                            {
                                id: "subscriptionType",
                                header: "Subscription Type",
                                cell: (item) =>
                                    <Button variant="link" iconAlign="right" onClick={() => {

                                        setIsEditSubscriptionTypeModalOpen(true)
                                        setEditSubscriptionTypeInfo(item);
                                        setSubscriptionType(item.subscriptionType)

                                    }}> {item.subscriptionType} </Button>

                            },
                            // {
                            //     id: "users",
                            //     header: "# of Users",
                            //     cell: item => item.companies.length > 0 ? (
                            //         <div>
                            //             <SpaceBetween size="xl">

                            //                 {
                            //                     item.companies.map(company => (
                            //                         <div key={company.id}>
                            //                             {company.numberOfUsers === null || undefined ? 0 : company.numberOfUsers}
                            //                         </div>
                            //                     ))
                            //                 }
                            //             </SpaceBetween>
                            //         </div>
                            //     ) : "No Users"
                            // },
                            {
                                id: "companies",
                                header: "Companies & Expiration Date of Subscription",
                                cell: item =>
                                    item.companies.length > 0
                                        ? (

                                            <div>
                                                <SpaceBetween size="s">
                                                    {item.companies.map(company => (
                                                        <div key={company.id} style={{ display: "flex", justifyContent: "space-between" }}>
                                                            {company.name} {company.subscriptionType === "paused" ? <div >
                                                                <p >{company.subscriptionExpiration}
                                                                    <span style={{ marginLeft: "22px" }} >
                                                                        <Icon variant="error" name="status-stopped" />
                                                                    </span>
                                                                </p>
                                                            </div> :
                                                                <div>
                                                                    <Button variant="link" iconAlign="right"
                                                                        onClick={() => {

                                                                            setIsEditExpirationModalOpen(true)
                                                                            setEditExpirationModalInfo(item);
                                                                            if (company.subscriptionExpiration === "none") {
                                                                                //set the "date" to todays date for the date picker
                                                                                const date = new Date().toISOString().split("T")[0]

                                                                                setExpirationDate(date)
                                                                            } else {
                                                                                const date = company.subscriptionExpiration
                                                                                const formattedDate = new Date(date as string).toISOString().split("T")[0]
                                                                                setExpirationDate(formattedDate)
                                                                            }
                                                                            setCurrentCompanyId(company.id)

                                                                        }}> {company.subscriptionExpiration} </Button>
                                                                    {isDateInFuture(company.subscriptionExpiration) ? willExpireWithinAWeek(company.subscriptionExpiration, formatDate(new Date())) ? <Icon variant="warning" name="status-warning" /> :
                                                                        <Icon variant="success" name="status-positive" />
                                                                        : <Icon variant="error" name="status-stopped" />}
                                                                </div>
                                                            }
                                                        </div>
                                                    ))}
                                                </SpaceBetween>
                                            </div>
                                        )
                                        : "No Companies",
                            },
                            {
                                id: "delete",
                                header: "Delete",
                                cell: (item) =>
                                    item.id !== currentCompanyOwner?.id ? (
                                        <Button
                                            variant="icon"
                                            iconName="remove"
                                            onClick={async () => {
                                                setIsDeleteModalOpen(true)
                                                setDeleteModalInfo(item);
                                            }}
                                        />
                                    ) : (
                                        <></>
                                    ),
                            },
                        ]}
                        items={displayData || []}
                        ariaLabels={{
                            selectionGroupLabel: "Items selection",
                            allItemsSelectionLabel: ({ selectedItems }) =>
                                `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
                                } selected`,
                        }}
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>No Company Owners</b>
                            </Box>
                        }
                        loading={isLoading}
                        loadingText="Loading owners..."
                    />
                </SpaceBetween>
                {/* Delete Modal */}
                <Modal
                    size="large"
                    onDismiss={() => {
                        setIsConfirmed(false)
                        setDeleteModalInfo(null)
                        setIsDeleteModalOpen(false)
                    }}
                    visible={!!deleteModalInfo?.id}
                    header={`Manage Deletions: `}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="link" onClick={() => {
                                    setIsConfirmed(false)
                                    setDeleteModalInfo(null)
                                    setIsDeleteModalOpen(false)
                                }}>
                                    Cancel
                                </Button>
                                {/* if we ever wanted to be able to cascade and delete all company, users, forms, reports etc -- this would be here */}
                                {/* <Button
                                    loadingText="Removing Entire Owner and companies..."
                                    variant="primary"
                                    onClick={() => prompt("delete")} //handle delete all -- needs an alert that this is permenent
                                >
                                    Delete Entire Account
                                </Button> */}
                            </SpaceBetween>
                        </Box>
                    }
                >

                    <Box key={deleteModalInfo?.id} padding={{ vertical: "xs" }}>
                        <SpaceBetween size="m">
                            <label>
                                <p>Please confirm you intend to delete a Company Owner or Company.  This action cannot be undone.</p>

                                {/* Confirmation checkbox */}
                                <input
                                    type="checkbox"
                                    checked={isConfirmed}
                                    onChange={(e) => setIsConfirmed(e.target.checked)}
                                />
                                Check to Agree
                                <p>I understand the consequences of this action. </p>
                            </label>
                            <div>
                                <strong>Company Owner Management: </strong>
                            </div>
                            <Button
                                variant="primary"
                                disabled={!isConfirmed}
                                onClick={() => { handleDeleteCompanyOwner() }}
                            >
                                Delete {deleteModalInfo?.name}
                            </Button>
                            <div>
                                {deleteModalInfo?.companies?.length ? (
                                    <strong>Company Management: </strong>
                                )
                                    : <></>}
                            </div>
                            <SpaceBetween direction="horizontal" size="m">

                                {deleteModalInfo?.companies.map((company, index) => (
                                    <div key={company.id} style={{ border: "2px solid black", width: "350px" }}>
                                        <Box padding={{ vertical: "xs", horizontal: "m" }}>
                                            <div>
                                                <strong>{company.name}</strong>
                                                <p>{company.description}</p>
                                            </div>
                                            <Button
                                                variant="primary"
                                                disabled={!isConfirmed}
                                                onClick={() => { handleSingleCompanyDelete(company.id) }}
                                            >
                                                Delete {company.name}
                                            </Button>
                                        </Box>
                                    </div>
                                ))}
                            </SpaceBetween>
                        </SpaceBetween>
                    </Box>
                </Modal>

                {/* Edit Subscription Type Modal */}
                <Modal
                    size="large"
                    onDismiss={() => {
                        setEditSubscriptionTypeInfo(null)
                        setIsEditSubscriptionTypeModalOpen(false)
                    }}
                    visible={isEditSubscriptionTypeModalOpen}
                    header={`Manage Subscription Type:`}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="link" onClick={() => {
                                    setEditSubscriptionTypeInfo(null)
                                    setIsEditSubscriptionTypeModalOpen(false)
                                }}>
                                    Cancel
                                </Button>
                                <Button
                                    loadingText="Updating Subscription Type..."
                                    variant="primary"
                                    disabled={!subscriptionType || isLoading}
                                    onClick={() => handleSubscriptionChange(subscriptionType as string)}
                                >
                                    Submit
                                </Button>
                            </SpaceBetween>
                        </Box>
                    }
                >

                    <Box key={editSubscriptionTypeInfo?.id} padding={{ vertical: "xs" }}>
                        <SpaceBetween size="m">
                            <RadioGroup
                                onChange={async ({ detail }) => {
                                    const selectedValue = detail.value;
                                    setSubscriptionType(selectedValue);
                                }}
                                value={subscriptionType}
                                items={SUBCRIPTION_TYPE_AND_DESCRIPTION.map(subscription => ({
                                    value: subscription.value,
                                    label: subscription.label,
                                    description: subscription.description
                                }))}
                            />

                        </SpaceBetween>
                    </Box>
                </Modal>

                {/* Edit Expiration Date Modal */}
                <Modal
                    size="large"
                    onDismiss={() => {
                        setEditExpirationModalInfo(null)
                        setIsEditExpirationModalOpen(false)
                    }}
                    visible={isEditExpirationModalOpen}
                    header={`Manage Expiration Date:`}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="m">
                                <Button variant="link" onClick={() => {
                                    setEditExpirationModalInfo(null)
                                    setIsEditExpirationModalOpen(false)
                                }}>
                                    Cancel
                                </Button>
                                <Button
                                    loadingText="Updating Expiration Date..."
                                    variant="primary"
                                    onClick={() => { handleExpirationDateChange(expirationDate as string) }}
                                >
                                    Submit
                                </Button>
                            </SpaceBetween>
                        </Box>
                    }
                >

                    <Box key={editSubscriptionTypeInfo?.id} padding={{ vertical: "xs" }}>
                        <SpaceBetween size="m">

                            {/* <div>
                                <strong>Expiration Date Management: </strong>
                            </div> */}
                            <FormField
                                label="Company Subscription Expiration Date"
                                constraintText="Use YYYY/MM/DD format."
                            >
                                <DatePicker
                                    onChange={async ({ detail }) => {
                                        const selectedValue = detail.value;
                                        setExpirationDate(selectedValue);
                                    }}
                                    value={expirationDate ? expirationDate : "none"}
                                    openCalendarAriaLabel={selectedDate =>
                                        "Choose certificate expiry date" +
                                        (selectedDate
                                            ? `, selected date is ${selectedDate}`
                                            : "")
                                    }
                                    placeholder="MM/DD/YYYY"
                                />
                            </FormField>
                        </SpaceBetween>
                    </Box>
                </Modal>




            </>
        )
        )}
        </>
    );
};

import { createContext, useContext, useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { generateClient } from "aws-amplify/api";

import * as Sentry from "@sentry/browser";

import {
  usersByCognitoId as getUsersByCognitoIdQuery,
  getCompany as getCompanyQuery,
} from "../graphql/queries";
import {
  updateUser as updateUserMutation,
} from "../graphql/mutations";
import { Company, User } from "../API";
import { IUserProvider, UserContextValue } from "../types";

const client = generateClient({ authMode: "userPool" });



export const UserContext = createContext<UserContextValue>({
  company: null,
  companyUser: null,
  getUserDetails: async () => { },
  isLoading: false,
  // newDiscrepancyNotification: false,
  // newDiscrepancyNotificationTypes: '',
  noNavBar: false,
  setCompany: () => { },
  setCompanyUser: () => { },
  // setNewDiscrepancyNotification: () => { },
  // setNewDiscrepancyNotificationTypes: () => { },
  setNoNavBar: () => { },
  setShouldUpdateUser: () => { },
  shouldUpdateUser: false,
});

// eslint-disable-next-line react-refresh/only-export-components
export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }: IUserProvider) => {
  const [companyUser, setCompanyUser] = useState<User | null>(null);
  const [company, setCompany] = useState<Company | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [noNavBar, setNoNavBar] = useState(false);
  const [shouldUpdateUser, setShouldUpdateUser] = useState(false);
  // const [newDiscrepancyNotification, setNewDiscrepancyNotification] = useState(false);
  // const [newDiscrepancyNotificationTypes, setNewDiscrepancyNotificationTypes] = useState<string>('{}');
  const { user } = useAuthenticator((context) => {
    return [context.user];
  });

  const getUserDetails = async () => {
    setIsLoading(true);
    try {
      const userResponse = await client.graphql({
        query: getUsersByCognitoIdQuery,
        variables: {
          cognitoId: user.userId,
        },
      });
      setCompanyUser(userResponse.data.usersByCognitoId.items[0]);
      // setNewDiscrepancyNotification(!!userResponse.data.usersByCognitoId.items[0]?.discrepancyNotification);
      // setNewDiscrepancyNotificationTypes(userResponse.data.usersByCognitoId.items[0]?.discrepancyNotificationTypes || '{}');
      if (userResponse.data.usersByCognitoId.items[0]?.companyId) {
        const companyResponse = await client.graphql({
          query: getCompanyQuery,
          variables: {
            id: userResponse.data.usersByCognitoId.items[0]?.companyId,
          },
        });
        setCompany(companyResponse.data.getCompany || null);
      }
    } catch (error) {
      console.log("error", error);
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      getUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (shouldUpdateUser && companyUser) {
      const updateUser = async () => {
        await client.graphql({
          query: updateUserMutation,
          variables: {
            input: {
              id: companyUser.id,
              // discrepancyNotification: newDiscrepancyNotification,
              // discrepancyNotificationTypes: newDiscrepancyNotificationTypes,
            },
          },
        });
        setShouldUpdateUser(false);
        setCompanyUser({
          ...companyUser,
          // discrepancyNotification: newDiscrepancyNotification,
          // discrepancyNotificationTypes: newDiscrepancyNotificationTypes,
        })
      };
      updateUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdateUser, companyUser]);

  const value = {
    company,
    companyUser,
    getUserDetails,
    isLoading,
    // newDiscrepancyNotification,
    // newDiscrepancyNotificationTypes,
    noNavBar,
    setCompany,
    setCompanyUser,
    // setNewDiscrepancyNotification,
    // setNewDiscrepancyNotificationTypes,
    setNoNavBar,
    setShouldUpdateUser,
    shouldUpdateUser,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

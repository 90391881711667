/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createCompanyOwner = /* GraphQL */ `mutation CreateCompanyOwner(
  $input: CreateCompanyOwnerInput!
  $condition: ModelCompanyOwnerConditionInput
) {
  createCompanyOwner(input: $input, condition: $condition) {
    id
    email
    name
    subscriptionType
    role
    permissions
    companies {
      items {
        id
        name
        type
        logo
        description
        subscriptionType
        subscriptionExpiration
        maxNumberUsers
        maxNumberEquipment
        companyOwnerId
        numberOfUsers
        createdAt
        updatedAt
        companyOwnerCompaniesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    siteAdmin
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyOwnerMutationVariables,
  APITypes.CreateCompanyOwnerMutation
>;
export const updateCompanyOwner = /* GraphQL */ `mutation UpdateCompanyOwner(
  $input: UpdateCompanyOwnerInput!
  $condition: ModelCompanyOwnerConditionInput
) {
  updateCompanyOwner(input: $input, condition: $condition) {
    id
    email
    name
    subscriptionType
    role
    permissions
    companies {
      items {
        id
        name
        type
        logo
        description
        subscriptionType
        subscriptionExpiration
        maxNumberUsers
        maxNumberEquipment
        companyOwnerId
        numberOfUsers
        createdAt
        updatedAt
        companyOwnerCompaniesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    siteAdmin
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyOwnerMutationVariables,
  APITypes.UpdateCompanyOwnerMutation
>;
export const deleteCompanyOwner = /* GraphQL */ `mutation DeleteCompanyOwner(
  $input: DeleteCompanyOwnerInput!
  $condition: ModelCompanyOwnerConditionInput
) {
  deleteCompanyOwner(input: $input, condition: $condition) {
    id
    email
    name
    subscriptionType
    role
    permissions
    companies {
      items {
        id
        name
        type
        logo
        description
        subscriptionType
        subscriptionExpiration
        maxNumberUsers
        maxNumberEquipment
        companyOwnerId
        numberOfUsers
        createdAt
        updatedAt
        companyOwnerCompaniesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    siteAdmin
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyOwnerMutationVariables,
  APITypes.DeleteCompanyOwnerMutation
>;
export const createCompany = /* GraphQL */ `mutation CreateCompany(
  $input: CreateCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  createCompany(input: $input, condition: $condition) {
    id
    name
    users {
      items {
        id
        name
        email
        domain
        role
        permissions
        cognitoId
        companyId
        createdAt
        updatedAt
        companyUsersId
        owner
        __typename
      }
      nextToken
      __typename
    }
    type
    logo
    description
    subscriptionType
    subscriptionExpiration
    maxNumberUsers
    maxNumberEquipment
    companyOwnerId
    numberOfUsers
    createdAt
    updatedAt
    companyOwnerCompaniesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyMutationVariables,
  APITypes.CreateCompanyMutation
>;
export const updateCompany = /* GraphQL */ `mutation UpdateCompany(
  $input: UpdateCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  updateCompany(input: $input, condition: $condition) {
    id
    name
    users {
      items {
        id
        name
        email
        domain
        role
        permissions
        cognitoId
        companyId
        createdAt
        updatedAt
        companyUsersId
        owner
        __typename
      }
      nextToken
      __typename
    }
    type
    logo
    description
    subscriptionType
    subscriptionExpiration
    maxNumberUsers
    maxNumberEquipment
    companyOwnerId
    numberOfUsers
    createdAt
    updatedAt
    companyOwnerCompaniesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyMutationVariables,
  APITypes.UpdateCompanyMutation
>;
export const deleteCompany = /* GraphQL */ `mutation DeleteCompany(
  $input: DeleteCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  deleteCompany(input: $input, condition: $condition) {
    id
    name
    users {
      items {
        id
        name
        email
        domain
        role
        permissions
        cognitoId
        companyId
        createdAt
        updatedAt
        companyUsersId
        owner
        __typename
      }
      nextToken
      __typename
    }
    type
    logo
    description
    subscriptionType
    subscriptionExpiration
    maxNumberUsers
    maxNumberEquipment
    companyOwnerId
    numberOfUsers
    createdAt
    updatedAt
    companyOwnerCompaniesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyMutationVariables,
  APITypes.DeleteCompanyMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    name
    email
    domain
    role
    permissions
    cognitoId
    companyId
    createdAt
    updatedAt
    companyUsersId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    name
    email
    domain
    role
    permissions
    cognitoId
    companyId
    createdAt
    updatedAt
    companyUsersId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    name
    email
    domain
    role
    permissions
    cognitoId
    companyId
    createdAt
    updatedAt
    companyUsersId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createForm = /* GraphQL */ `mutation CreateForm(
  $input: CreateFormInput!
  $condition: ModelFormConditionInput
) {
  createForm(input: $input, condition: $condition) {
    id
    name
    companyId
    noMachineHours
    formData
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFormMutationVariables,
  APITypes.CreateFormMutation
>;
export const updateForm = /* GraphQL */ `mutation UpdateForm(
  $input: UpdateFormInput!
  $condition: ModelFormConditionInput
) {
  updateForm(input: $input, condition: $condition) {
    id
    name
    companyId
    noMachineHours
    formData
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFormMutationVariables,
  APITypes.UpdateFormMutation
>;
export const deleteForm = /* GraphQL */ `mutation DeleteForm(
  $input: DeleteFormInput!
  $condition: ModelFormConditionInput
) {
  deleteForm(input: $input, condition: $condition) {
    id
    name
    companyId
    noMachineHours
    formData
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFormMutationVariables,
  APITypes.DeleteFormMutation
>;
export const createEmailNotifications = /* GraphQL */ `mutation CreateEmailNotifications(
  $input: CreateEmailNotificationsInput!
  $condition: ModelEmailNotificationsConditionInput
) {
  createEmailNotifications(input: $input, condition: $condition) {
    id
    companyId
    name
    email
    discrepancyNotification
    discrepancyNotificationTypes
    permissions
    domain
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEmailNotificationsMutationVariables,
  APITypes.CreateEmailNotificationsMutation
>;
export const updateEmailNotifications = /* GraphQL */ `mutation UpdateEmailNotifications(
  $input: UpdateEmailNotificationsInput!
  $condition: ModelEmailNotificationsConditionInput
) {
  updateEmailNotifications(input: $input, condition: $condition) {
    id
    companyId
    name
    email
    discrepancyNotification
    discrepancyNotificationTypes
    permissions
    domain
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEmailNotificationsMutationVariables,
  APITypes.UpdateEmailNotificationsMutation
>;
export const deleteEmailNotifications = /* GraphQL */ `mutation DeleteEmailNotifications(
  $input: DeleteEmailNotificationsInput!
  $condition: ModelEmailNotificationsConditionInput
) {
  deleteEmailNotifications(input: $input, condition: $condition) {
    id
    companyId
    name
    email
    discrepancyNotification
    discrepancyNotificationTypes
    permissions
    domain
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEmailNotificationsMutationVariables,
  APITypes.DeleteEmailNotificationsMutation
>;
export const createEquipment = /* GraphQL */ `mutation CreateEquipment(
  $input: CreateEquipmentInput!
  $condition: ModelEquipmentConditionInput
) {
  createEquipment(input: $input, condition: $condition) {
    id
    name
    companyId
    formId
    equipment {
      id
      name
      machineHours
      lastServiceHours
      __typename
    }
    machineHours
    lastServiceHours
    image
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEquipmentMutationVariables,
  APITypes.CreateEquipmentMutation
>;
export const updateEquipment = /* GraphQL */ `mutation UpdateEquipment(
  $input: UpdateEquipmentInput!
  $condition: ModelEquipmentConditionInput
) {
  updateEquipment(input: $input, condition: $condition) {
    id
    name
    companyId
    formId
    equipment {
      id
      name
      machineHours
      lastServiceHours
      __typename
    }
    machineHours
    lastServiceHours
    image
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEquipmentMutationVariables,
  APITypes.UpdateEquipmentMutation
>;
export const deleteEquipment = /* GraphQL */ `mutation DeleteEquipment(
  $input: DeleteEquipmentInput!
  $condition: ModelEquipmentConditionInput
) {
  deleteEquipment(input: $input, condition: $condition) {
    id
    name
    companyId
    formId
    equipment {
      id
      name
      machineHours
      lastServiceHours
      __typename
    }
    machineHours
    lastServiceHours
    image
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEquipmentMutationVariables,
  APITypes.DeleteEquipmentMutation
>;
export const createDiscrepancy = /* GraphQL */ `mutation CreateDiscrepancy(
  $input: CreateDiscrepancyInput!
  $condition: ModelDiscrepancyConditionInput
) {
  createDiscrepancy(input: $input, condition: $condition) {
    id
    companyId
    equipmentId
    unitId
    questionIndex
    equipmentName
    initialStatus
    status
    log
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDiscrepancyMutationVariables,
  APITypes.CreateDiscrepancyMutation
>;
export const updateDiscrepancy = /* GraphQL */ `mutation UpdateDiscrepancy(
  $input: UpdateDiscrepancyInput!
  $condition: ModelDiscrepancyConditionInput
) {
  updateDiscrepancy(input: $input, condition: $condition) {
    id
    companyId
    equipmentId
    unitId
    questionIndex
    equipmentName
    initialStatus
    status
    log
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDiscrepancyMutationVariables,
  APITypes.UpdateDiscrepancyMutation
>;
export const deleteDiscrepancy = /* GraphQL */ `mutation DeleteDiscrepancy(
  $input: DeleteDiscrepancyInput!
  $condition: ModelDiscrepancyConditionInput
) {
  deleteDiscrepancy(input: $input, condition: $condition) {
    id
    companyId
    equipmentId
    unitId
    questionIndex
    equipmentName
    initialStatus
    status
    log
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDiscrepancyMutationVariables,
  APITypes.DeleteDiscrepancyMutation
>;
export const createArchivedDiscrepancy = /* GraphQL */ `mutation CreateArchivedDiscrepancy(
  $input: CreateArchivedDiscrepancyInput!
  $condition: ModelArchivedDiscrepancyConditionInput
) {
  createArchivedDiscrepancy(input: $input, condition: $condition) {
    id
    companyId
    equipmentId
    unitId
    questionIndex
    equipmentName
    initialStatus
    status
    log
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateArchivedDiscrepancyMutationVariables,
  APITypes.CreateArchivedDiscrepancyMutation
>;
export const updateArchivedDiscrepancy = /* GraphQL */ `mutation UpdateArchivedDiscrepancy(
  $input: UpdateArchivedDiscrepancyInput!
  $condition: ModelArchivedDiscrepancyConditionInput
) {
  updateArchivedDiscrepancy(input: $input, condition: $condition) {
    id
    companyId
    equipmentId
    unitId
    questionIndex
    equipmentName
    initialStatus
    status
    log
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateArchivedDiscrepancyMutationVariables,
  APITypes.UpdateArchivedDiscrepancyMutation
>;
export const deleteArchivedDiscrepancy = /* GraphQL */ `mutation DeleteArchivedDiscrepancy(
  $input: DeleteArchivedDiscrepancyInput!
  $condition: ModelArchivedDiscrepancyConditionInput
) {
  deleteArchivedDiscrepancy(input: $input, condition: $condition) {
    id
    companyId
    equipmentId
    unitId
    questionIndex
    equipmentName
    initialStatus
    status
    log
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteArchivedDiscrepancyMutationVariables,
  APITypes.DeleteArchivedDiscrepancyMutation
>;
export const createReport = /* GraphQL */ `mutation CreateReport(
  $input: CreateReportInput!
  $condition: ModelReportConditionInput
) {
  createReport(input: $input, condition: $condition) {
    id
    companyId
    equipmentId
    unitId
    unitName
    equipmentName
    userId
    name
    userData
    userLocation
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReportMutationVariables,
  APITypes.CreateReportMutation
>;
export const updateReport = /* GraphQL */ `mutation UpdateReport(
  $input: UpdateReportInput!
  $condition: ModelReportConditionInput
) {
  updateReport(input: $input, condition: $condition) {
    id
    companyId
    equipmentId
    unitId
    unitName
    equipmentName
    userId
    name
    userData
    userLocation
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReportMutationVariables,
  APITypes.UpdateReportMutation
>;
export const deleteReport = /* GraphQL */ `mutation DeleteReport(
  $input: DeleteReportInput!
  $condition: ModelReportConditionInput
) {
  deleteReport(input: $input, condition: $condition) {
    id
    companyId
    equipmentId
    unitId
    unitName
    equipmentName
    userId
    name
    userData
    userLocation
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReportMutationVariables,
  APITypes.DeleteReportMutation
>;
export const createErrors = /* GraphQL */ `mutation CreateErrors(
  $input: CreateErrorsInput!
  $condition: ModelErrorsConditionInput
) {
  createErrors(input: $input, condition: $condition) {
    id
    errors
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateErrorsMutationVariables,
  APITypes.CreateErrorsMutation
>;
export const updateErrors = /* GraphQL */ `mutation UpdateErrors(
  $input: UpdateErrorsInput!
  $condition: ModelErrorsConditionInput
) {
  updateErrors(input: $input, condition: $condition) {
    id
    errors
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateErrorsMutationVariables,
  APITypes.UpdateErrorsMutation
>;
export const deleteErrors = /* GraphQL */ `mutation DeleteErrors(
  $input: DeleteErrorsInput!
  $condition: ModelErrorsConditionInput
) {
  deleteErrors(input: $input, condition: $condition) {
    id
    errors
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteErrorsMutationVariables,
  APITypes.DeleteErrorsMutation
>;
